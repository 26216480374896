/* Pages/Dashboard/dashboard.module.css */

.logoutBtn {
    height: '30px';
    width: '100px';
  }
  
  .dashboardPage {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }